<template>
    <div v-if="validationErrors && validationErrors != ''">
        <ul class="alert alert-danger" style="list-style:none">
            <li v-for="(value, key, index) in validationErrors" :key="index">{{ value.charAt(0).toUpperCase() + value.slice(1) }}</li>
        </ul>
    </div>
</template>
<script>
export default {
    name: "ValidationErrors",
    data(){
        return {
            
        }
    },
    props: ['errors'],
    computed: {
        validationErrors(){
            // let formErrors = Object.values(this.errors);
            // formErrors = formErrors.flat();
            // return formErrors;
            let formErrors = this.errors
            if (Array.isArray(formErrors) || (typeof formErrors === 'object' && formErrors !== null)) {
                formErrors = Object.values(formErrors).flat();
            } else {
                formErrors = [formErrors];
            }
            return formErrors;
        }
    }
}
</script>
